<template>
  <div>
    <!-- Question -->
    <section class="question-section">
      <!-- <div>問題 {{ currentQuestion.number }}</div> -->
      <div class="question-section__description">
        {{ currentQuestion.titleZh }}
      </div>
    </section>

    <!-- Answer -->
    <section class="answer-section">
      <div
        class="flex-row flex-align-center my-10"
        v-for="(item, index) in currentQuestion.options"
        :key="index"
      >
        <div
          v-if="selectedValue === item.value"
          class="answer-section__selected-indicator"
        ></div>
        <div
          class="answer-section__option-container br-8"
          :class="{
            'answer-section__option-container--selected':
              selectedValue === item.value
          }"
        >
          <div class="answer-section__option-description">
            {{ item.textZh }}
          </div>
          <div class="answer-section__option-checkbox">
            <el-button @click="onOptionSelect(item.value)">選擇</el-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.question-section {
  padding: 20px 0px;
  &__description {
    font-size: 24px;
  }
}

.answer-section {
  margin-bottom: 20px;
  &__selected-indicator {
    height: 20px;
    width: 20px;
    background-color: #75d46f;
    border-radius: 50%;
    margin: 0px 5px;
  }

  &__option-container {
    width: 85%;
    padding: 5px 5px 5px 15px;
    border: 1px solid #d3d3d3;

    display: flex;
    justify-content: space-between;
    background-color: #fff;
    color: #414c64;
  }

  &__option-container--selected {
    background-color: transparent;
    color: #fff;
  }

  &__option-description {
    display: flex;
    align-items: center;
  }
}

.action-bar {
  display: flex;
  line-height: 50px;

  div {
    margin-right: 20px;
  }
}
</style>

<script>
export default {
  name: "QuestionSection",
  props: {
    questionNumber: Number
  },
  data: function() {
    return {};
  },
  methods: {
    onOptionSelect(value) {
      this.$store.dispatch("upsertAnsweredQuestion", {
        questionNumber: this.questionNumber,
        selectedValue: value
      });
    }
  },
  computed: {
    selectedValue() {
      return this.$store.getters.selectedValueOfCurrentDtQuestion;
    },
    currentQuestion() {
      return this.$store.getters.questionByNumber(Number(this.questionNumber));
    }
  }
};
</script>

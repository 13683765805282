<template>
  <div class="page-container">
    <div class="sticky">
      <section class="page-header">
        <div class="page-header__nav">
          <div class="page-header__nav-item">
            1 問答
          </div>
          <div class="page-header__nav-item page-header__nav-item--highlighted">
            2 分析
          </div>
          <div class="page-header__nav-item">
            3 結果
          </div>
        </div>
      </section>

      <section class="disclaimer-container">
        <p>
          本問卷是為了調查與你的體質有關的一些情況，從而為今後你的健康管理和臨床診治等提供參考，請閱讀每條問題，根據自己最近的實際情況或感覺作答。
          如果未能肯定如何回答某問題時，請選擇最接近你實際情況的答案。
        </p>
        <p class="warning-text">
          請注意：若分析結果同時得出兩種或以上體質，請盡快找尋醫師作專業檢查及診斷。
        </p>
      </section>
    </div>

    <section class="questions-container">
      <QuestionSection :questionNumber="currentDtQuestionNumber" />
    </section>

    <section class="action-container">
      <section v-if="!hasFinishedAllDtQuestions">
        <el-button
          class="full-width-btn"
          type="success"
          @click="onPrevBtnClicked()"
        >
          上一題
        </el-button>
        <el-button
          class="full-width-btn"
          type="success"
          :disabled="!isFormTouched"
          @click="onNextBtnClicked()"
        >
          下一題
        </el-button>
      </section>
      <section v-if="hasFinishedAllDtQuestions">
        <el-button
          class="full-width-btn"
          type="success"
          @click="onSubmitBtnClicked()"
        >
          送出並分析
        </el-button>
      </section>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.page-container {
  background: linear-gradient(#535cd9, #2b90cb);
}

.sticky {
  position: sticky;
  background-color: #535cd9;
  top: 0;
  padding: 15px 0px;

  @media screen and (max-width: 600px) {
    position: static;
  }
}

.page-header {
  display: flex;
  justify-content: flex-end;
  height: 40px;
  line-height: 40px;

  &__nav {
    display: flex;
    justify-content: flex-end;
  }

  &__nav-item {
    opacity: 0.3;
    margin: 0px 10px;
    font-weight: 400;
  }

  &__nav-item--highlighted {
    opacity: 1;
  }
}

.disclaimer-container {
  margin: 5px 25px;
  border-radius: 8px;
  text-align: center;
  padding: 15px 10px;
  background-color: #f2f2f2;
  color: #000;
  font-size: 14px;

  .warning-text {
    font-weight: 400;
    text-decoration: underline;
  }
}

.questions-container {
  padding: 5px 25px;
}

.action-container {
  padding: 5px 25px 25px 25px;

  .validation-section {
    margin: 15px 0px;

    &__title {
      font-weight: 400;
    }

    &__content {
      font-size: 16px;
    }
  }
}
</style>

<script>
import QuestionSection from "@/components/QuestionSection.vue";

export default {
  name: "analysis",
  data() {
    return {};
  },
  components: {
    QuestionSection
  },
  methods: {
    onPrevBtnClicked() {
      this.$store.dispatch("revertDtQuestionIndex");
    },
    onNextBtnClicked() {
      this.$store.dispatch("bumpDtQuestionIndex");
    },
    onSubmitBtnClicked() {
      this.$store.dispatch("getScores", {
        algoName: "CCMQ_DT"
      });
    }
  },
  computed: {
    currentDtQuestionNumber() {
      return this.$store.getters.currentDtQuestionNumber;
    },
    hasFinishedAllDtQuestions() {
      return this.$store.getters.hasFinishedAllDtQuestions;
    },
    isFormTouched() {
      return this.$store.getters.selectedValueOfCurrentDtQuestion;
    }
  }
};
</script>

import { render, staticRenderFns } from "./QuestionSection.vue?vue&type=template&id=3fd23735&scoped=true"
import script from "./QuestionSection.vue?vue&type=script&lang=js"
export * from "./QuestionSection.vue?vue&type=script&lang=js"
import style0 from "./QuestionSection.vue?vue&type=style&index=0&id=3fd23735&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fd23735",
  null
  
)

export default component.exports
import { render, staticRenderFns } from "./IntelligentAnalysis.vue?vue&type=template&id=2c2ad610&scoped=true"
import script from "./IntelligentAnalysis.vue?vue&type=script&lang=js"
export * from "./IntelligentAnalysis.vue?vue&type=script&lang=js"
import style0 from "./IntelligentAnalysis.vue?vue&type=style&index=0&id=2c2ad610&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c2ad610",
  null
  
)

export default component.exports